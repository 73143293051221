<template>
  <div class="page page--404">
    <div class="section" id="sec-1" data-sec="1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="title fs-18 fs-md-28 ta-center mb-5">{{ cc.sec1.title }}</h1>
            <div class="img img--404">
              <img src="/img/img-error-404.png" alt="">
            </div>
            <router-link tag="a" :to="`/${$i18n.locale}` + cc.sec1.btn.to" target="_blank" class="btn btn-1-blue btn-w-fc btn-h-52 mx-auto px-4">
              <span>{{ cc.sec1.btn.text }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    cc () {
      return this.$t('pages.404')
    }
  }
}
</script>
